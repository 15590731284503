.projects-cont {
  position: relative;
}

.image-viewer-cont {
  position: absolute;
  width: 60%;
  height: 60vh;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

.image-viewer-cont img {
  object-fit: contain;
}

.project-tabs-cont {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.project-tab {
  width: 200px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: 1px solid #2e3092;
  border-radius: 3px;
  background-color: transparent;
}

.project-tab h6 {
  text-transform: uppercase;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  margin: auto 0;
}

.tab-active {
  background-color: #ed1c23;
  border-color: gray;
}

.tab-active h6 {
  color: #fff;
}

.caption-cont {
  position: relative;
}

.project-caption {
  /* background-color: #2e3092; */
  background: linear-gradient(
    111.9deg,
    rgb(221, 49, 49) 0.3%,
    rgb(0, 8, 119) 100.7%
  );
  width: 100px;
  padding: 5px 0;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
}

.projects-btn {
  background-color: #ed1c23;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 200px;
  padding: 5px;
  margin: 0 auto;
  margin-top: 10px;
  cursor: pointer;
}

.projects-btn h6 {
  margin: auto 0;
  text-transform: uppercase;
}

.projects-btn i {
  margin: auto 0;
  padding: 0;
  font-size: 24px;
  margin-left: 10px;
}

.project-subtitle {
  margin: 20px 0 5px 0;
}

.project-subtitle .h6 {
  text-transform: uppercase;
  border-bottom: 3px solid #ed1c23;
  width: max-content;
  padding: 10px 0;
}
