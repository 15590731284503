.tips-banner {
  width: 100%;
  height: 300px;
  background: url("/public/banners/painting.jpg");
  background-repeat: no-repeat;
  background-position-y: 28%;
  background-size: cover;
  padding: 30px;
}

.banner-info {
  max-width: 700px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-info h2 {
  font-size: 24px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
  text-align: center;
}

.banner-info p {
  font-size: 18px;
  font-weight: normal;
  font-family: Arial, sans-serif;
  margin-bottom: 0;
}

.tips-btn {
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 10px;
  width: 200px;
  color: #fff;
  text-transform: uppercase;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.tips-btn .more-icon {
  margin-left: 10px;
}

.tips-btn:hover {
  background-color: initial;
}

/* Painting Tips Page */
.tips-cont {
  width: 60%;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
}

.tips-cont h2 {
  font-size: 24px;
  color: #555555;
  font-weight: bold;
  margin: 10px 0;
}

.tips-item h6 {
  font-weight: bold;
  color: #555555;
}

.tips-item p {
  font-size: 16px;
  font-weight: normal;
  font-family: Arial, sans-serif;
}
/* Painting Tips Page */

@media (max-width: 768.5px) {
  .tips-cont {
    width: 90%;
  }
}

@media (max-width: 438.5px) {
  .tips-banner {
    height: auto;
  }

  .banner-info {
    padding: 0;
  }

  .banner-info h2 {
    font-size: 20px;
  }

  .banner-info p {
    font-size: 16px;
  }

  .tips-cont {
    width: 100%;
    padding: 20px 20px;
  }

  .tips-cont h2 {
    font-size: 20px;
  }

  .tips-item {
    font-size: 16px;
    padding: 3px 0;
  }
}
