/* Top Header */
.active-header {
  background-color: #fff;
  position: fixed;
  top: 0;
  height: 70px;
  z-index: 9;
  transition: 0.5s;
}

.active-header .header-block {
  position: fixed;
  top: 70px;
  width: 100%;
  background-color: #fff;
}

.bg-header{
  background: linear-gradient(111.9deg,
        rgb(0, 8, 119) 0.3%,
        rgb(221, 49, 49) 100.7%)
}
.top-header {
  background: linear-gradient(
    111.9deg,
    rgb(221, 49, 49) 0.3%,
    rgb(0, 8, 119) 100.7%
  );
  height: 60px;
  width: 100%;
  padding-left: 250px;
  padding-right: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top-left {
  display: flex;
  align-items: center;
}

.left-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.left-item .top-icon {
  color: #ffffff;
  font-weight: 100;
  font-size: 18px;
  margin-left: 30px;
}

.left-item .h4 {
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin: 0 auto;
}

.top-btn {
  background-color: #2e3092;
  padding: 3px 10px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
  transition: 0.7s linear;
  margin-left: 30px;
}

.top-btn:hover {
  background-color: #ed1c23;
}

/* Navbar */
#navbar {
  transition: all 0.3s ease;
}

.header-right {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header-center {
  background: linear-gradient(
    111.9deg,
    rgb(0, 8, 119) 0.3%,
    rgb(221, 49, 49) 100.7%
  );
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
}

.header-list {
  height: 70px;
  display: flex;
  align-items: center;
}
.header-menu {
  display: none;
  align-items: center;
  cursor: pointer;
}

.menu-icon {
  font-size: 24px;
  color: gray;
}

.header-items {
  display: flex;
}

.header-item {
  margin: 3px 10px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.header-item .h5 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  display: inline; /* Changed to inline */
}

.active-nav {
  border-bottom: 3px solid #2e3092;
  padding-bottom: 5px;
  background-color: #fff;
  color: #2e3092 !important;
  height: 100%;
  margin: auto 0;
  padding: 10px;
}

/* mobile-header */
.mobile-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid #2e3092;
  position: relative;
}

.mobile-logo {
  width: 250px;
  height: 200px;
}

.header-menu {
  display: none;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  /* height: 100vh; */
  width: 60%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  overflow-y: hidden;
}

.header-row {
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.block-header {
  display: none;
}

.header-block {
  padding-left: 70px;
}

/* mobile-header */

/* Media Query for small devices */
@media (max-width: 768px) {
  .header-items {
    display: none;
  }
  .header-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #000;
    border: 1px solid #2e3092;
    border-radius: 5px;
    font-size: 24px;
    position: absolute;
    bottom: 15px;
    right: 10px;
    background-color: #fff;
  }

  .block-header {
    /* display: none; */
    height: 0;
    overflow: hidden;
    width: 100%;
    opacity: 0;
    transition: all 0.3s linear;
  }

  .block-header.visible {
    display: block;
    opacity: 1;
    height: max-content;
  }

  .block-header .header-item .h5 {
    color: #000;
  }

  .header-center {
    background: linear-gradient(
      111.9deg,
      rgb(0, 8, 119) 0.3% rgb(221, 49, 49) 100.7%
    );
  }

  .header-left {
    flex: 100%;
    justify-content: center;
    align-items: center;
  }

  .top-header {
    display: none;
  }

  .logo {
    border: none;
  }

  .header-list {
    margin-left: 0;
  }

  .header-item {
    margin: 5px 0;
  }

  .header-item .h5 {
    display: flex; /* Display as flex for vertical alignment */
    justify-content: flex-start;
  }
}
